//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SharedComponent',
  inheritAttrs: false,
  props: {
    content: {
      type: [Object, Array],
      default: () => []
    }
  },
  setup (props) {
    const effectiveContent = computed(() => {
      return props.content?.content ?? props.content
    })
    return {
      effectiveContent
    }
  }
})
