//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'BmfSharedComponent',
  inheritAttrs: false,
  props: {
    content: {
      type: [Object, Array],
      default: () => {}
    }
  }
})
